import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styles from "./style.module.css";
import { Stack } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import withdrawIcon from "../../utils/images/withdraw-dollar-sign-svgrepo-com.svg";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import GamesIcon from "@mui/icons-material/Games";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DashboardComp from "./dashboard";
import WithdrawDepositComp from "./withdrawDeposit";
import VipRegistrationComp from "./vipRegistration";
import AmountSettingComp from "./amountSetting";
import AdminBankDetailsComp from "./adminBankDetails";
import GameComp from "./game";
import GameSlotComp from "./gameSlot";
import BannersComp from "./banners";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  console.log(selectedTab);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Stack className={styles.drawer}>
        <Typography
          className={styles.navHead}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="h6"
          mr={3}>
          AmarGaming
        </Typography>
      </Stack>

      <Divider />
      <List>
        {[
          "Dashboard",
          "Withdrawal Deposit",
          "VIP registration page",
          "Amount setting page",
          "Admin Bank Details",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => setSelectedTab(index)}
              sx={{ backgroundColor: index === selectedTab ? "#eae7e7" : "" }}>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                {index === 0 ? (
                  <DashboardIcon sx={{ width: "24px" }} />
                ) : index === 1 ? (
                  <img
                    src={withdrawIcon}
                    alt="withdraw"
                    className={styles.iconWithdraw}
                  />
                ) : index === 2 ? (
                  <HowToRegIcon />
                ) : index === 3 ? (
                  <SettingsIcon />
                ) : index === 4 ? (
                  <AccountBalanceIcon />
                ) : index === 5 ? (
                  <VideogameAssetIcon />
                ) : index === 6 ? (
                  <GamesIcon />
                ) : (
                  <ViewCarouselIcon />
                )}
              </ListItemIcon>
              <Typography
                sx={{ fontWeight: "600", color: "grey", marginLeft: "5px" }}>
                {text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
          <Stack className={styles.mainHeader}>
            <Typography variant="h6" component="div" className={styles.navHead}>
              AmarGaming
            </Typography>
            <Typography className={styles.adminTxt}>Admin</Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <Toolbar />
        {selectedTab === 0 ? (
          <DashboardComp />
        ) : selectedTab === 1 ? (
          <WithdrawDepositComp />
        ) : selectedTab === 2 ? (
          <VipRegistrationComp />
        ) : selectedTab === 3 ? (
          <AmountSettingComp />
        ) : selectedTab === 4 ? (
          <AdminBankDetailsComp />
        ) : selectedTab === 5 ? (
          <GameComp />
        ) : selectedTab === 6 ? (
          <GameSlotComp />
        ) : (
          <BannersComp />
        )}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
