import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FaceIcon from "@mui/icons-material/Face";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";
import CachedIcon from "@mui/icons-material/Cached";
import walletIcon from "../../utils/images/wallet-svgrepo-com.svg";
import HomeIcon from "@mui/icons-material/Home";
import { LoadingButton } from "@mui/lab";
import SquareIcon from "@mui/icons-material/Square";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../../schemas/auth/loginSchema";
import RHFTextField from "../../components/RFH/RFHtextfield";
import { depositSchema } from "../../schemas/DepositSchema";
import Api from "../../services/axios";
import toast from "react-hot-toast";
import TransactionComp from "./transactions";
import LogoutIcon from "@mui/icons-material/Logout";

export default function DepositComp() {
  const navigate = useNavigate();
  const [fund, setFund] = useState("0");
  const [adminDetails, setAdminDetails] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    Api.get("/user/get-user-fund")
      .then((res) => {
        setFund(res.data.data.fund);
      })
      .catch((err) => {
        console.log("available fund err ==", err);
      });

    Api.get("/user/get-admin-qr-details")
      .then((res) => {
        console.log("admin QR err ==", res);
        setAdminDetails(res.data.data);
      })
      .catch((err) => {
        console.log("admin QR err ==", err);
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(depositSchema),
    defaultValues: {
      amount: "",
      utr_number: "",
      upi: "",
    },
  });
  console.log("error == ", methods.formState.errors);

  const onSubmit = (formData) => {
    console.log("form data == ", formData);
    Api.post("/user/raise-payment-request", {
      transaction_type: "deposit",
      transaction_amount: formData.amount,
      account_details: formData.upi,
      transaction_medium: "UPI",
      admin_account_details: adminDetails.account_number,
      utr_number: formData.utr_number,
    })
      .then((res) => {
        console.log("deposit response == ", res);
        toast.success(res.data.message);
        methods.reset();
      })
      .catch((err) => {
        console.log("deposit error == ", err);
        toast.error(err.response.data.error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log("admin details == ", adminDetails.qr_code);
  return (
    <Box className={styles.mainCont}>
      <Box className={styles.backCont}>
        <Stack className={styles.navCont}>
          <HomeIcon
            className={styles.leftIcon}
            onClick={() => navigate("/dashboard")}
          />
          <Typography className={styles.logo}>Deposit</Typography>
          <FaceIcon className={styles.profileIcon} onClick={handleClick} />
        </Stack>
        <Box className={styles.walletSection}>
          <Stack className={styles.walletBalanceCont}>
            <img src={walletIcon} alt="wallet" className={styles.walletIcon} />
            <Typography className={styles.balanceTxt}>
              Available balance
            </Typography>
          </Stack>
          <Typography className={styles.amount}>
            &#8377; {fund}
            <CachedIcon
              className={styles.reloadICon}
              onClick={() => window.location.reload()}
            />
          </Typography>
          {/* <Stack className={styles.btnCont}>
            <Typography className={styles.stars}>****</Typography>
            <Typography className={styles.stars}>****</Typography>
          </Stack> */}
        </Box>
      </Box>
      <Box className={styles.walletSection}>
        <img
          src={`data:image/jpeg;base64,${adminDetails?.qr_code}`}
          alt="qr"
          style={{ maxWidth: "100%" }}
        />
      </Box>
      <Box className={styles.walletSection}>
        <Stack alignItems="center" direction="row">
          <AccountBalanceWalletIcon sx={{ color: "#FB5B5B" }} />
          <Typography className={styles.deposit}>Deposit Amount</Typography>
        </Stack>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={1} my={1} className={styles.form}>
              <RHFTextField name="amount" placeholder="Enter amount" />
              <RHFTextField name="upi" placeholder="Enter UPI" />
              <RHFTextField name="utr_number" placeholder="Enter UTR number" />
            </Stack>
            <LoadingButton
              loading={false}
              className={styles.btnLogin}
              type="submit">
              Deposit
            </LoadingButton>
          </form>
        </FormProvider>
      </Box>

      <Box className={styles.contentCont}>
        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          If the transfer time is up, please fill out the deposit form again.
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          The transfer amount must match the order you created, otherwise the
          money cannot be credited successfully.
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          If you transfer the wrong amount, our company will not be responsible
          for the lost amount!
        </Typography>

        <Typography className={styles.warnTxt}>
          Note: do not cancel the deposit order after the money has been
          transferred.
        </Typography>
      </Box>
      <Box className={styles.contentCont}>
        <Typography>Transaction History</Typography>
        <TransactionComp />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
          className={styles.menuitem}>
          <FaceIcon className={styles.menuIco} /> Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} className={styles.menuitem}>
          <LogoutIcon className={styles.menuIco} /> Log Out
        </MenuItem>
      </Menu>
    </Box>
  );
}
