import React from "react";
import { useNavigate } from "react-router";
import styles from "./style.module.css";
import Navbar from "./navbar";
import CarouselComp from "./carousal";
import { Box } from "@mui/material";
import GameSection from "./gameSection";
import WinnersComp from "./winners";
import AboutUsComp from "./aboutUs";

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <main>
      <Box className={styles.mainContainer}>
        <Navbar />
        <CarouselComp />
        <GameSection/>
        <WinnersComp/>
        <AboutUsComp/>
      </Box>
    </main>
  );
}
