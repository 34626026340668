import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import axios from "axios";
import zeroImage from "../../../utils/images/zero.png";
import oneImage from "../../../utils/images/one.png";
import twoImage from "../../../utils/images/two.png";
import threeImage from "../../../utils/images/three.png";
import fourImage from "../../../utils/images/four.png";
import fiveImage from "../../../utils/images/five.png";
import sixImage from "../../../utils/images/six.png";
import sevenImage from "../../../utils/images/seven.png";
import eightImage from "../../../utils/images/eight.png";
import nineImage from "../../../utils/images/nine.png";
import HistoryComp from "../event";
import BidComp from "../Bid";
import UserGameHistory from "../userGameHistory";
import HowToPlayComp from "../HowToPlayPopup";

export default function TenMin({ id }) {
  const [seconds, setSeconds] = useState(10 * 60 - 1);
  const [gameState, setGameState] = useState();
  const [category, setCategory] = useState();
  const [bidPopup, setBidPopup] = useState(false);
  const [selectedGame, setSelectedGame] = useState("");
  const [bidDuration, setBidDuration] = useState();
  const [historyTabs, setHistoryTab] = useState(true);
  const [htpPopup, setHtpPopup] = useState(false);
  // console.log("game type id 10 min ", id)
  useEffect(() => {
    let interval;
    if (seconds === 0 || seconds === 599) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/color-prediction/getCurrentGameDetails?gameId=1&gameTypeId=${id}`
        )
        .then((res) => {
          console.log("one minute == ", res);
          setGameState(res.data.data);
          setBidDuration(599 - res.data.data.biddingDurationInSec);
          setSeconds(
            (new Date(res.data.data.endTime).getTime() -
              new Date(res.data.data.serviceTime).getTime()) /
              1000
          );

          console.log(
            "time",
            (new Date(res.data.data.endTime).getTime() -
              new Date(res.data.data.serviceTime).getTime()) /
              1000
          );
        })
        .catch((err) => {
          console.log("one minute err ===", err);
        });
    }

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleBid = (gameName, cate) => {
    setCategory(cate);
    setSelectedGame(gameName);
    setBidPopup(true);
  };

  return (
    <>
      <Stack className={styles.countDownCont}>
        <Box>
          <Stack className={styles.howToPlayCont}>
            <SpeakerNotesIcon className={styles.listIcon} />
            <Typography
              className={styles.htpTxt}
              onClick={() => setHtpPopup(true)}>
              How to play
            </Typography>
          </Stack>
          <Typography className={styles.winGoTxt}>Win Go 1 min</Typography>
          <Stack direction="row" spacing="4px">
            <img src={fiveImage} alt="zero" className={styles.numberShort} />
            <img src={twoImage} alt="zero" className={styles.numberShort} />
            <img src={sevenImage} alt="zero" className={styles.numberShort} />
            <img src={fourImage} alt="zero" className={styles.numberShort} />
            <img src={nineImage} alt="zero" className={styles.numberShort} />
          </Stack>
        </Box>
        <Divider
          className={styles.divider}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Stack className={styles.rightCont}>
          <Typography className={styles.timeTxt}>Time Remaining</Typography>
          <Stack className={styles.timerCont}>
            {formatTime(seconds)
              .split("")
              .map((ele) => (
                <span className={styles.item}>{ele}</span>
              ))}
          </Stack>
          <Typography className={styles.id}>{gameState?.period}</Typography>
        </Stack>
      </Stack>

      <Box className={styles.colorPlayground}>
        <Stack
          className={styles.tempCont}
          sx={{ pointerEvents: seconds <= bidDuration ? "none" : "" }}>
          <Box
            className={styles.grnClr}
            onClick={() => handleBid("GREEN", "color")}>
            Green
          </Box>
          <Box
            className={styles.vltClr}
            onClick={() => handleBid("VIOLET", "color")}>
            Violet
          </Box>
          <Box
            className={styles.redClr}
            onClick={() => handleBid("RED", "color")}>
            Red
          </Box>
        </Stack>

        <Box
          className={styles.tempCont}
          sx={{ pointerEvents: seconds <= bidDuration ? "none" : "" }}>
          <Stack direction="row" justifyContent="space-around">
            <img
              src={zeroImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("0", "number")}
            />
            <img
              src={oneImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("1", "number")}
            />
            <img
              src={twoImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("2", "number")}
            />
            <img
              src={threeImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("3", "number")}
            />
            <img
              src={fourImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("4", "number")}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-around" mt={2}>
            <img
              src={fiveImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("5", "number")}
            />
            <img
              src={sixImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("6", "number")}
            />
            <img
              src={sevenImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("7", "number")}
            />
            <img
              src={eightImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("8", "number")}
            />
            <img
              src={nineImage}
              alt="zero"
              className={styles.number}
              onClick={() => handleBid("9", "number")}
            />
          </Stack>
        </Box>

        <Box
          className={styles.bigSmallCont}
          sx={{ pointerEvents: seconds <= bidDuration ? "none" : "" }}>
          <Button
            className={styles.withdrawBtn}
            onClick={() => handleBid("BIG", "size")}>
            Big
          </Button>
          <Button
            className={styles.depositBtn}
            onClick={() => handleBid("SMALL", "size")}>
            Small
          </Button>
        </Box>

        <Box className={styles.tempCont}>
          <Stack direction="row">
            <Typography
              className={historyTabs ? styles.histHead : styles.inActiveTab}
              onClick={() => (historyTabs ? "" : setHistoryTab(true))}>
              Game History
            </Typography>
            <Typography
              className={historyTabs ? styles.inActiveTab : styles.histHead}
              onClick={() => (historyTabs ? setHistoryTab(false) : "")}>
              My History
            </Typography>
          </Stack>
          {historyTabs ? (
            <HistoryComp gameId={"104"} />
          ) : (
            <UserGameHistory gameId={"104"} />
          )}
        </Box>
        {bidPopup && (
          <BidComp
            openPop={bidPopup}
            handleClose={() => setBidPopup(false)}
            headind={selectedGame}
            gameTypeId={"104"}
            period={gameState?.period}
            category={category}
          />
        )}
      </Box>
      <HowToPlayComp
        minute={"10"}
        orderMin={"9"}
        orderSec={"45"}
        issues={"144"}
        open={htpPopup}
        handleClose={() => setHtpPopup(false)}
      />
    </>
  );
}
