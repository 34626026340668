import axios from "axios";
import React, { useEffect, useState } from "react";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { Box, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Api from "../../../services/axios";

export default function UserGameHistory({ gameId }) {
  const [limitState, setLimit] = useState(10);
  const [offsetState, setOfffset] = useState(0);
  const [history, setHistory] = useState([{}]);
  const mobileNumber = localStorage.getItem("user");

  useEffect(() => {
    Api.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/color-prediction/getUserGameHistory?gameTypeId=${gameId}&mobileNumber=${mobileNumber}&limit=${limitState}&offset=${
        offsetState * 10
      }`
    )
      .then((res) => {
        console.log("history response == ", res);
        setHistory(res.data.data);
      })
      .catch((err) => {
        console.log("history component == ", err);
      });
  }, [limitState, offsetState]);

  const columns = [
    {
      field: "col1",
      headerName: "Period",
      //   minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Bet Type",
      //   minWidth: 127,
      flex: 0.5,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Amount",
      //   minWidth: 126,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Status",
      //   minWidth: 120,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];
  return (
    <Box mt={1}>
      {/* {history.map((ele) => (
        <h1>{ele.color}</h1>
      ))} */}
      <DataGridComp
        columns={columns}
        borderEnable={false}
        initialRows={
          history
            ? history.map((ele, idx) => {
                return {
                  id: idx,
                  col1: ele.game_period,
                  col2: ele.bid_selection,
                  col3: ele.bid_amount,
                  col4: ele.status,
                };
              })
            : []
        }
        footer={true}
      />
      <Stack direction="row" sx={{ width: "100%", justifyContent: "center" }}>
        <ArrowBackIcon
          onClick={() => setOfffset((prev) => prev - 1)}
          sx={{ visibility: offsetState <= 0 ? "hidden" : "visible" }}
        />
        <Typography>{offsetState}</Typography>
        <ArrowForwardIcon onClick={() => setOfffset((prev) => prev + 1)} />
      </Stack>
    </Box>
  );
}
